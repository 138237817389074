/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import { createApp } from 'vue';

/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */

const app = createApp({
	data() {
		return {
			newsMessages: null,
			online_usrs: null,
			offline_usrs: null
		}
	},
	created() {
		window.Echo.private(`call`)
			.listen('BeelineCallEvent', (e) => {
				Snackbar.show({
					text: `BeelineCallEvent`,
					actionTextColor: '#fff',
					backgroundColor: '#1abc9c',
					pos: 'bottom-left',
					duration: 10000
				});
			});
		window.Echo.private(`check.message`)
			.listen('NewMessage', (e) => {
				this.newsMessages = e.info;
				Snackbar.show({
					text: `Вам пришло новое сообщение! <a href="/chat/user/${e.info}">Перейти</a>`,
					actionTextColor: '#fff',
					backgroundColor: '#1abc9c',
					pos: 'bottom-left',
					duration: 10000
				});
				$('#new-message').addClass('show');
				this.updateChatSidebar();
			});
		window.Echo.private(`update.sessions`)
			.listen('NewSession', (e) => {
				this.updateChatSidebar();
				this.updateAllUsers();
				this.updateProfile();
				this.updateUserUrl(e.userId)
			});
		let that = this;
		setInterval(() => {
			that.updateChatSidebar();
			that.updateAllUsers();
			that.updateProfile();
		}, 60000);
	},
	methods: {
		updateUsers(users) {
			this.online_usrs = users.online;
			this.offline_usrs = users.offline;
		},
		updateChatSidebar() {
			if (location.pathname == '/chats') {
				axios.post('/chats/updatesessions').then(response => {
					this.online_usrs = response.data.users_online;
					this.offline_usrs = response.data.users_offline;
				});
			}
		},
		updateAllUsers() {
			if (location.pathname == '/users/all') {
				let page, sort;
				let params = new URLSearchParams(document.location.search);
				if (params.get('page')) {
					page = params.get('page');
				}
				if (params.get('sort')) {
					sort = params.get('sort');
				}
				axios.post('/users/all', { page, sort, render: true }).then(response => {
					$('#users-table').html(response.data.users);
				});
			}
		},
		updateProfile() {
			if ($('#user-profile-content').length > 0) {
				let id = $('#user-profile-content').attr('data-userid');
				let page, event;
				let params = new URLSearchParams(document.location.search);
				if (params.get('page')) {
					page = params.get('page');
				}
				if (params.get('event')) {
					event = params.get('event');
				}
				axios.post('/users/profile/' + id, { page, event, render: true }).then(response => {
					$('#user-profile-content').html(response.data.profile);
					$("#response-table").on('mousewheel DOMMouseScroll', function (event) {
						event.preventDefault();
						var delta = Math.max(-1, Math.min(1, (event.originalEvent.wheelDelta || -event.originalEvent.detail)));
						$(this).scrollLeft($(this).scrollLeft() - (delta * 40));
					});
				});
			}
		},
		updateUserUrl(userId) {
			if ($('.current-chat-user-name').length > 0) {
				axios.post('/chats/updatesessions', { userId }).then(response => {
					$('#session-cur-url').attr('href', response.data.url);
					$('#session-cur-url').html(response.data.url);
				});
			}
		}
	}
});


import ChatMessages from './components/ChatMessages.vue';
import ChatForm from './components/ChatForm.vue';
import ChatContainer from './components/ChatContainer.vue';
import ChatSessionContainer from './components/ChatSession/ChatSessionContainer.vue';
import ChatSession from './components/ChatSession/ChatSession.vue';
//import ExampleComponent from './components/ExampleComponent.vue';
app.component('chat-messages', ChatMessages);
app.component('chat-form', ChatForm);
app.component('chat-container', ChatContainer);
app.component('chat-session-container', ChatSessionContainer);
app.component('chat-session', ChatSession);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//Object.entries(import.meta.glob('./**/*.vue', { eager: true })).forEach(([path, definition]) => {
//app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
//});

/**
 * Finally, we will attach the application instance to a HTML element with
 * an "id" attribute of "app". This element is included with the "auth"
 * scaffolding. Otherwise, you will need to add an element yourself.
 */

app.mount('#app')