<template>
	<div class="chat-box-inner">
		<div class="chat-meta-user">
			<div class="current-chat-user-name">
				<span class="d-flex">
					<span class="name">Пользователь #{{ user.id }}</span>
					<span :class="{ 'active': typing == true }" id="istyping">Пользователь печатает...</span>
				</span>
			</div>
			<div class="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"
					fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
					class="feather feather-menu mail-menu d-lg-none">
					<line x1="3" y1="12" x2="21" y2="12"></line>
					<line x1="3" y1="6" x2="21" y2="6"></line>
					<line x1="3" y1="18" x2="21" y2="18"></line>
				</svg>
			</div>
		</div>
		<div class="chat-conversation-box">
			<div id="chat-conversation-box-scroll" class="chat-conversation-box-scroll">
				<div class="chat" data-chat="person1">
					<chat-messages :messages="messages"></chat-messages>
				</div>
				<span id="end-chat"></span>
			</div>

		</div>
		<div class="chat-scroll-bottom">
			<button class="btn btn-dark btn-block"><span id="scrol-bottom-text">Непрочитанное сообщение</span><i
					class="fa-light fa-down"></i></button>
		</div>

	</div>

	<chat-form v-on:messagesent="addMessage" :user="user" :fastanswers="fastanswers"></chat-form>
</template>
<script>
export default {
	data() {
		return {
			messages: [],
			typing: false,
			getScrollContainer: null,
			typingInterval: null
		}
	},
	props: ['user', 'fastanswers'],
	created() {
		let that = this;
		$(document).ready(function () {
			new PerfectScrollbar('.chat-conversation-box', {
				suppressScrollX: true
			});
			that.getScrollContainer = document.querySelector('.chat-conversation-box');
			that.getScrollContainer.addEventListener('ps-scroll-y', () => {
				let currScroll = that.currScroll();
				if (currScroll >= 89) {
					$('.chat-scroll-bottom').removeClass('show');
					$('#scrol-bottom-text').removeClass('show');
				} else {
					$('.chat-scroll-bottom').addClass('show');
				}
			});

			$(document).on('click', '.chat-scroll-bottom', function () {
				that.chatScrollBottom(false, 100);
				that.readMessage();
			});
		})
		this.fetchMessages();
		window.Echo.private(`chat.${this.user.id}`)
			.listen('MessageSent', (e) => {
				this.messages[e.date].push(e.message);
				let currScroll = this.currScroll();
				this.chatScrollBottom(false, currScroll);
				if (currScroll < 89) {
					$('#scrol-bottom-text').addClass('show');
				} else {
					this.readMessage();
				}
			}).listen('IsTyping', (e) => {
				this.typing = e.typing;
				if (this.typing) {
					let dots = '';
					this.typingInterval = setInterval(function () {
						$('#istyping').html('Пользователь печатает' + dots);
						if (dots != '...') {
							dots += '.';
						} else {
							dots = '';
						}
					}, 400);
				} else {
					$('#istyping').html('');
					clearInterval(this.typingInterval);

				}
			});
	},
	methods: {
		currScroll() {
			let height = $(".chat-conversation-box").height();
			let mainContentHeight = this.getScrollContainer.scrollTop;
			let scrollHeight = $(".chat-conversation-box")[0].scrollHeight - height;
			let currScroll = mainContentHeight / scrollHeight * 100;
			return currScroll;
		},
		readMessage() {
			axios.post('/chat/readmsg', { user_id: this.user.id }).then(response => {
				/*if (response.data.cntUnread == 0) {
					$('#new-message').removeClass('show');
				}*/
			});
		},
		chatScrollBottom(firstScroll = false, currScroll) {
			let anim = 1000;
			if (firstScroll) anim = 50;
			if (firstScroll || currScroll >= 89) {
				$('.chat-conversation-box').scroll();
				$(".chat-conversation-box").animate({ scrollTop: $(".chat-conversation-box")[0].scrollHeight }, anim);
			}
		},
		addMessage(message) {
			var dt = new Date();
			var minutes = dt.getMinutes();
			var time = dt.getHours() + ":" + (minutes < 10 ? '0' : '') + minutes;
			var month = dt.getMonth() + 1;
			var day = dt.getDate();

			var date = dt.getFullYear() + '-' +
				(month < 10 ? '0' : '') + month + '-' +
				(day < 10 ? '0' : '') + day;
			message.time = time;
			message.type = 'admin';
			this.messages[date].push(message);
			this.chatScrollBottom(true, 100);
			axios.post(apiUrl + '/api/messages', message);
		},
		fetchMessages() {
			axios.post(apiUrl + `/api/getmessages`, { user_id: this.user.id, isAdmin: true }).then(response => {
				this.messages = response.data.messages;
				this.readMessage();
				if (response.data.unreadMsgs == 0) {
					$('#new-message').removeClass('show');
				}
				let that = this;
				setTimeout(function () {
					that.chatScrollBottom(true);
				}, 1);
			});
		},
	},
};
</script>