<template>
	<div class="open-chat-tab" :class="{ online_session: isOnline }" :data-user-id="user.id">
		<div :id="'chat_session_' + user.id" class="person">
			<div class="user-info">
				<div class="f-body">
					<div class="row">
						<div class="col-12 col-lg-6">
							<span class="user-name">
								<b>Пользователь #{{ user.id }}</b>
							</span>
						</div>
						<div class="col-12 col-lg-6">
							<span class="user-meta-time" v-if="user.last_msg_time">
								{{ user.last_msg_time }}
							</span>
						</div>
						<div class="col-12">
							<span>Комментарий: {{ user.comment }}</span>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-12" v-if="isOnline || !user.last_msg_text">
							<span class="preview">
								<a target="_blank" href="{{ user.url }}">
									{{ user.url }}
								</a>
							</span>
						</div>
						<div class="col-12 mb-2 last-message" v-if="user.last_msg_text">
							<span>
								{{ user.last_msg_text }}
							</span>
						</div>
						<div class="col-12">
							<span class="preview">
								{{ user.country }} > {{ user.region }} > {{ user.city }}
							</span>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-12">
							<span :class="{ 'show': user.messages_count > 0 }" class="preview session-unread">
								Непрочитано сообщений:
								<span class="cnt-unread">
									{{ user.messages_count }}
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
$(document).on('click', '.open-chat-tab', function (e) {
	if (e.target.nodeName !== 'A') {
		let id = $(this).attr('data-user-id');
		location.href = '/chat/user/' + id;
	}
})
export default {
	data() {
		return {
			info: null,
		}
	},
	props: ["user", "isOnline"],
	created() {

	}
};
</script>