<template>
	<div class="chat-footer">
		<div class="input-group">
			<div class="btn-group dropup input-group-append" role="group">
				<button id="btnDropUp" type="button" class="btn btn-regular dropdown-toggle" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false"><span id="fast-answers">Быстрые ответы</span> <svg
						xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
						stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
						class="feather feather-chevron-up">
						<polyline points="18 15 12 9 6 15"></polyline>
					</svg></button>
				<div class="dropdown-menu" aria-labelledby="btnDropUp">
					<a v-for="answer in fastanswers" @click="() => sendMessage(answer)" href="javascript:void(0);"
						class="dropdown-item">{{ answer }}</a>
				</div>
			</div>
			<input placeholder="Введите сообщение..." v-model="newMessage" @keyup.enter="() => sendMessage(null)"
				@focus="setTyping(true)" @blur="setTyping(false)" type="text" class="form-control">
			<div class="input-group-append">
				<button @click="() => sendMessage(null)" class="btn btn-success" type="button"><i
						class="fa-regular fa-paper-plane"></i>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	//Takes the "user" props from <chat-form> … :user="{{ Auth::user() }}"></chat-form> in the parent chat.blade.php.
	props: ["user", "fastanswers"],
	emits: ["messagesent", "istyping"],
	data() {
		return {
			newMessage: "",
		};
	},
	methods: {
		sendMessage(message = null) {
			//Emit a "messagesent" event including the user who sent the message along with the message content
			this.$emit("messagesent", {
				//newMessage is bound to the earlier "btn-input" input field
				message: message == null ? this.newMessage : message,
				user_id: this.user.id
			});
			//Clear the input
			this.newMessage = "";
		},
		setTyping(data) {
			axios.post(apiUrl + '/api/typing', { typing: data, user_id: this.user.id });
		}
	},
};
</script>
