<template>
	<div class="people">
		<div class="alert alert-light-success border-0 mb-0" role="alert">
			<strong>Онлайн</strong> пользователи
		</div>
		<template v-for="online in online_usrs">
			<chat-session :user="online" :isOnline="true"></chat-session>
		</template>
		<div class="alert alert-light-danger border-0 mb-0" role="alert">
			<strong>Офлайн</strong> пользователи
		</div>
		<!--<div class="search">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
				stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
				class="feather feather-search">
				<circle cx="11" cy="11" r="8"></circle>
				<line x1="21" y1="21" x2="16.65" y2="16.65"></line>
			</svg>
			<input type="text" class="form-control" v-model="search" @keyup.enter="searchUser" placeholder="Поиск...">
		</div>-->
		<template v-for="offline in offline_usrs">
			<chat-session :user="offline" :isOnline="false"></chat-session>
		</template>
	</div>
</template>
<script>
export default {
	data() {
		return {
			cnt: 10,
			search: "",
		}
	},
	props: ["users_online", "users_offline", 'online_usrs', 'offline_usrs'],
	created() {
		let that = this;
		that.$emit('updateparent', { online: that.users_online, offline: that.users_offline });
		$(document).ready(function () {
			new PerfectScrollbar('.people', {
				suppressScrollX: true
			});
			let getScrollContainer = document.querySelector('.people');
			getScrollContainer.addEventListener('ps-y-reach-end', () => {
				that.cnt += 10;
				axios.post('/chats/updatesessions', { cnt: that.cnt }).then(response => {
					that.$emit('updateparent', { online: response.data.users_online, offline: response.data.users_offline });
				});
			});
		})
	}
};
</script>