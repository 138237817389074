<template>
	<template v-for="(dates, indexD) in messages">
		<div class="conversation-start mb-5 mt-5">
			<span>{{ indexD }}</span>
		</div>
		<template v-for="messages in dates">
			<div class="imessage" :class="{ 'you': messages.type == 'user', 'me': messages.type == 'admin' }">
				<div class="bubble" :class="{ 'you': messages.type == 'user', 'me': messages.type == 'admin' }">
					{{ messages.message }}
				</div>
				<time>{{ messages.time }}</time>
			</div>
		</template>
	</template>
</template>
<script>
export default {
	props: ["messages"],
};
</script>
